import { useEffect } from 'react';
import { TooltipFormatterCallbackFunction } from 'highcharts';
import { DatetimeRangeInput } from '../../../../gql/graphql';
import { usePaymentChartLazyQuery } from '../../../../operations/queries/paymentChart';
import LineChart from '../../../charts/LineChart';
import { Col } from '../../../layout/Grid';
import { ReportsOverviewStyles } from '../ReportsOverviewPage';
import formatCurrency from '../../../../utils/formatCurrency';
import { GREYSCALE } from '../../../../styles/colors';

type ReportsTotalPaymentsProps = {
  currentFilter: string;
  dateFormatter: () => DatetimeRangeInput;
  dollarFormatter: TooltipFormatterCallbackFunction;
  paymentsAmount: number | undefined;
  paymentsTotalAmount: number | undefined;
};

export default function ReportsTotalPayments({
  currentFilter,
  dateFormatter,
  dollarFormatter,
  paymentsAmount,
  paymentsTotalAmount,
}: ReportsTotalPaymentsProps) {
  const [loadPaymentChart, { data: paymentChartData }] = usePaymentChartLazyQuery({
    variables: {
      datetimeRange: dateFormatter(),
    },
  });

  useEffect(() => {
    loadPaymentChart({
      variables: {
        datetimeRange: dateFormatter(),
      },
    });
  }, [dateFormatter, loadPaymentChart]);

  return (
    <Col md={8}>
      <ReportsOverviewStyles.TallChart
        title="Total Payments"
        loading={
          !paymentChartData || paymentsAmount === undefined || paymentsTotalAmount === undefined
        }
        link="View Payment Receipts"
        linkTo="/reports/paymentreceipt"
        fullHeight
        amount={paymentsAmount}
        secondary={
          paymentsTotalAmount !== undefined && paymentsTotalAmount > 0
            ? `+ ${formatCurrency(paymentsTotalAmount)} Pending`
            : ''
        }
        valueId="payments"
        labelId="reports-total-payments-headline"
      >
        <LineChart
          currentFilter={currentFilter}
          data={paymentChartData?.paymentChart.charts ?? []}
          labels={paymentChartData?.paymentChart.labels ?? []}
          customOptions={{
            chart: {
              className: 'screen-chart',
              height: 420,
            },
            accessibility: {
              point: {
                valuePrefix: '$',
              },
            },
            title: {
              text: '',
            },
            yAxis: {
              accessibility: {
                description: 'total payments made, in dollars',
                rangeDescription: '',
              },
            },
            tooltip: {
              formatter: dollarFormatter,
            },
          }}
        />
        <LineChart
          data={paymentChartData?.paymentChart.charts ?? []}
          labels={paymentChartData?.paymentChart.labels ?? []}
          customOptions={{
            accessibility: {
              enabled: false,
            },
            chart: {
              className: 'print-chart',
              height: 320,
              width: 600,
              marginBottom: 45,
              marginLeft: 45,
            },
            xAxis: {
              labels: {
                rotation: -35,
                style: {
                  fontSize: '0.9em',
                },
              },
            },
            yAxis: {
              title: {
                text: '',
              },
              labels: {
                // eslint-disable-next-line no-template-curly-in-string
                format: '${value}',
                enabled: true,
                style: {
                  color: GREYSCALE.black,
                  fontSize: '0.9em',
                  fontWeight: '600',
                },
                y: 1,
              },
              gridLineWidth: 1,
            },
            tooltip: {
              formatter: dollarFormatter,
            },
          }}
        />
      </ReportsOverviewStyles.TallChart>
    </Col>
  );
}
