import { useEffect } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { Col } from '../layout/Grid';
import { FileFormat } from '../../gql/graphql';
import showPdf from '../../utils/showPdf';

type PrintPreviewProps = {
  shipmentLabelFileFormat: FileFormat;
  url: string;
};

export default function PdfPreview({ shipmentLabelFileFormat, url }: PrintPreviewProps) {
  const [, windowHeight] = useWindowSize();
  const height = `${windowHeight / 2}px`;
  const fallbackLink = `<p>This browser does not support inline PDFs. Please download the PDF to view it: <a href="${url.replace(
    'force/0',
    'force/1',
  )}">Download PDF</a></p>`;

  useEffect(() => {
    // render PDF preview with PDF
    showPdf(url, height, fallbackLink);
  }, [url, height, fallbackLink]);

  if (shipmentLabelFileFormat === 'ZPL') {
    return null;
  }

  return <Col md={12} spaceBelow data-testid="pdf-object" id="pdf-preview-wrapper" />;
}
