import { PropsWithChildren, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { LinkButton } from '../Link';
import { createClaimInsuranceLink } from '../../operations/mutations/createClaimInsuranceLinkMutation';
import ContentLoading from '../loading/ContentLoading';
import { SPACING } from '../../styles/spacing';

type ClaimInsureShieldInsuranceLinkProps = {
  shipmentId: string;
};

const Styled = {
  LinkButtonWithLoading: styled(LinkButton)`
    display: inline-flex;
    flex-direction: row;
    column-gap: ${SPACING.xs};

    span {
      white-space: nowrap;
    }
  `,
};

export default function ClaimInsureShieldInsuranceLink({
  shipmentId,
  children = 'File an Insurance Claim',
}: PropsWithChildren<ClaimInsureShieldInsuranceLinkProps>) {
  const [createLink] = useMutation(createClaimInsuranceLink);
  const [isLoading, setIsLoading] = useState(false);

  const handleClaimClick = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await createLink({
        variables: { shipmentId },
      });
      const url = result?.data?.createClaimInsuranceLink.url;

      if (url) {
        window.open(url);
      }
    } finally {
      setIsLoading(false);
    }
  }, [createLink, shipmentId]);

  return (
    <Styled.LinkButtonWithLoading
      onClick={handleClaimClick}
      disabled={isLoading}
      data-testid="upsc-portal"
    >
      <span>{children}</span>
      {isLoading && <ContentLoading height={20} size={20} />}
    </Styled.LinkButtonWithLoading>
  );
}
