import { InsuranceDetails } from './ShipmentServiceTypes';
import ClaimInsureShieldInsuranceLink from './ClaimInsureShieldInsuranceLink';
import formatCurrency from '../../utils/formatCurrency';
import IntercomArticleLink from '../IntercomArticleLink';
import { INTERCOM_ARTICLE_LINKS } from '../../constants';

type CarrierLiabilityHintProps = {
  shipmentId: string;
  insuranceDetails: InsuranceDetails;
  isUpscInsuranceActive: boolean;
};

export default function CarrierLiabilityHint({
  shipmentId,
  insuranceDetails,
  isUpscInsuranceActive,
}: CarrierLiabilityHintProps) {
  if (insuranceDetails.hasOnlyCarrierLiabilityInsurance) {
    return (
      <span>
        {insuranceDetails.insuredValue ? (
          <span>
            {formatCurrency(insuranceDetails.insuredValue, {
              showCents: false,
            })}{' '}
            carrier liability
          </span>
        ) : (
          <span>Includes carrier liability</span>
        )}{' '}
        (
        {isUpscInsuranceActive && insuranceDetails.insuredValueType === 'UPS' ? (
          <ClaimInsureShieldInsuranceLink shipmentId={shipmentId}>
            File claim
          </ClaimInsureShieldInsuranceLink>
        ) : (
          <IntercomArticleLink href={INTERCOM_ARTICLE_LINKS.fileInsurance}>
            File claim
          </IntercomArticleLink>
        )}
        )
      </span>
    );
  }

  return null;
}
