import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { ExternalLink } from '../Link';
import Checkbox from '../form/Checkbox';
import FormControl from '../form/FormControl';
import { Col, Row } from '../layout/Grid';
import Modal, { ModalButtonBar } from './Modal';

type UpsAcceptTermsModalProps = {
  open: boolean;
  buttonText: string;
  headline: string;
  onCancel: () => void;
  submitMerchantAccount: () => void;
  merchantAccountSubmitting: boolean;
};

function UpsAcceptTermsModal({
  open,
  buttonText,
  headline,
  onCancel,
  submitMerchantAccount,
  merchantAccountSubmitting,
}: UpsAcceptTermsModalProps) {
  return (
    <Modal width={800} open={open} onClose={onCancel} title={headline}>
      <Row>
        <Col md={12} spaceBelow>
          You can schedule pickups on the <b>Ship</b> page or drop UPS packages off at over 85,000
          locations, including The UPS Store® and Drop Boxes, CVS Pharmacy, Michaels Stores, Advance
          Auto Parts, and local retailers that offer UPS services.
        </Col>
        <Col md={12} spaceBelow>
          To proceed, please accept the terms below.
        </Col>
        <Formik
          initialValues={{ agreed: false }}
          onSubmit={submitMerchantAccount}
          validationSchema={yup.object<{ agreed: boolean }>({
            agreed: yup.boolean().required().isTrue(),
          })}
        >
          <Form>
            <Col md={12}>
              <FormControl
                as={Checkbox}
                name="agreed"
                type="checkbox"
                label={
                  <>
                    By checking this box, I accept and agree to the
                    <ExternalLink href="https://www.ups.com/assets/resources/media/en_US/Ground-Saver.pdf">
                      {' '}
                      UPS Terms and Conditions{' '}
                    </ExternalLink>
                    and the
                    <ExternalLink href="https://www.ups.com/assets/resources/media/en_US/UTA.pdf">
                      {' '}
                      UPS Technology Agreement
                    </ExternalLink>
                    , and I will not ship any items in violation of the
                    <ExternalLink href="https://www.ups.com/us/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page">
                      {' '}
                      UPS List of Prohibited and Restricted Items for Shipping
                    </ExternalLink>
                    .
                  </>
                }
              />
            </Col>
            <Col md={12}>
              <ModalButtonBar
                buttonText={buttonText}
                buttonVariant="success"
                buttonSize="xLarge"
                buttonProgress={merchantAccountSubmitting}
                buttonDisabled={merchantAccountSubmitting}
                buttonType="submit"
              />
            </Col>
          </Form>
        </Formik>
      </Row>
    </Modal>
  );
}

export default UpsAcceptTermsModal;
